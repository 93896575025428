document.documentElement.className = document.documentElement.className.replace("no-js", "js", );

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
    offset: 0,
});

import smoothscroll from "smoothscroll-polyfill";
if (!CSS.supports("scroll-behavior", "smooth")) {
    smoothscroll.polyfill()
    const links = document.querySelectorAll("a[href^=\"#\"]")
    links.forEach(link => {
        const id = link.attributes["href"].value.slice(1)
        const target = document.getElementById(id)
        link.addEventListener("click", () => {
            target.scrollIntoView({ behavior: "smooth" });
        });
    });
}

import "./snow";
snow.start({
    flakeCount: 150,
    color: "#F0F9FF",
});

document.querySelectorAll(".faq-button").forEach(btn => {
    btn.addEventListener("click", function (e) {
        e.preventDefault();
        btn.querySelector("svg").classList.toggle("-rotate-180");
        btn.parentElement.parentElement.querySelector(".faq-text").classList.toggle("max-h-0");
        btn.parentElement.parentElement.querySelector(".faq-text").classList.toggle("max-h-screen");
    });
});
